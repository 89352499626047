/**
 * AboutMarketplace Component
 *
 * This component renders the About Marketplace page, which includes the event header,
 * about event section, positions table, and bet slip component.
 *
 */
import { Skeleton, Table } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Marketplace.scss";
import BetSlip from "./BetSlip";
import AboutEvent from "./AboutEvent";
import EventHeader from "./EventHeader";
import { columns } from "./marketPlace.helper";
import { msgs } from "../../utils/appConstants";
import CommonSuccessModal from "./CommonSuccessModal";
import { usePositions } from "../../Hooks/usePositions";
import useScrollToTop from "../../Hooks/useScrollToTop";
import { BackIcon } from "../../assets/StoreAsset/StoreAsset";
import { useGetMarketPlace } from "../../Hooks/useGetMarketPlace";
import { useTablePagination } from "../../Hooks/useTablePagination";
import CommonConfirmationModal from "../../Common/CommonConfirmModal/CommonConfirmModal";
import useCurrentWidth from "../../Hooks/useCurrentWidth";
import PaginationSelect from "../../Common/PaginationSelect/PaginationSelect";
import { useGetCoins } from "../../Hooks/useGetCoins";

const AboutMarketplace = () => {
  const width = useCurrentWidth();
  const navigate = useNavigate();
  // Scroll to top on component mount
  useScrollToTop();
  // Access global state from context
  const { coinList } = useGetCoins();

  // Fetch marketplace data and handle related actions
  const {
    bets,
    callApis,
    metaData,
    getDetails,
    getYesNoBets,
    getBetsOfParticularEventOfCurrentUser,
  } = useGetMarketPlace();
  // Handle table pagination
  const { handleTableChange, tableParams } = useTablePagination(
    getBetsOfParticularEventOfCurrentUser,
    metaData?.total || 0
  );
  // Handle positions and modal visibility
  const {
    updated,
    rowDetails,
    contractDetails,
    onRow,
    handleYes,
    showSuccessModal,
    showConfirmModal,
    toggleConfirmModal,
    toggleSuccessModal,
  } = usePositions({ getYesNoBets });

  return (
    <div className="container">
      {/* Back Navigation */}
      <div className="marketBack">
        <p onClick={() => navigate(-1)}>
          <BackIcon />
          {msgs.back}
        </p>
      </div>
      {/* About Marketplace Content */}
      <div className="aboutMarket">
        <div className="leftSec">
          <EventHeader
            data={metaData?.eventDetails}
            bets={bets}
            isLoading={metaData?.loading}
          />
          <div className="anoutEvent">
            <AboutEvent metaData={{ data: metaData?.eventDetails, coinList }} />
            {/* Bet Slip for mobile view */}
            {width < 1100 ? (
              <div style={{ marginTop: 30 }}>
                <BetSlip
                  callApi={() => {
                    callApis();
                    getDetails();
                  }}
                  betClosureDate={metaData?.eventDetails?.bettingClosureTime}
                  updated={updated}
                />
              </div>
            ) : null}
            <div className="positionTable">
              <h2>{msgs?.positions}</h2>
              {metaData?.loading ? (
                <div
                  className="no-events-found"
                  data-testid="skeleton-container"
                >
                  <Skeleton active paragraph={{ rows: 5 }} />
                </div>
              ) : (
                <Table
                  dataSource={metaData?.data}
                  columns={columns(rowDetails?.isLoading)}
                  className="commonTable"
                  pagination={{
                    selectComponentClass: PaginationSelect,
                    hideOnSinglePage: true,
                    ...tableParams?.pagination,
                  }}
                  onChange={handleTableChange}
                  onRow={onRow}
                />
              )}
            </div>
          </div>
        </div>
        {/* Bet Slip for webview*/}
        {width > 1100 ? (
          <BetSlip
            callApi={() => {
              callApis();
              getDetails();
            }}
            betClosureDate={metaData?.eventDetails?.bettingClosureTime}
            updated={updated}
          />
        ) : null}
        {/* Confirmation Modal */}
        <CommonConfirmationModal
          show={showConfirmModal}
          header={msgs.areYouSure}
          desc={msgs.initiateWithdraw}
          loading={contractDetails?.isLoading}
          handleYes={handleYes}
          handleCancel={toggleConfirmModal}
        />
        <CommonSuccessModal
          show={showSuccessModal}
          header={msgs.successful}
          desc={msgs.withdrawSuccessDesc}
          handleCancel={() => {
            toggleSuccessModal();
            toggleConfirmModal();
            getBetsOfParticularEventOfCurrentUser(bets);
            getYesNoBets(rowDetails?.eventId);
          }}
        />
      </div>
    </div>
  );
};

export default AboutMarketplace;
