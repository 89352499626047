/**
 * TopEvents Component
 *
 * Displays a list of top events with a "View All" button if there are more events available.
 *
 */
import React from "react";
import { useLocation } from "react-router-dom";
import "./Events.scss";
import EventsCard from "./EventsCard";
import { msgs } from "../../../utils/appConstants";
import { useGetTopEvents } from "../../../Hooks/useGetTopEvents";
import { ButtonCustom } from "../../../Common/ButtonCustom/ButtonCustom";
import useClearSearchOnHome from "../../../Hooks/useClearSearchOnHome";

const TopEvents = () => {
  const {
    metaData,
    filter,
    topEventsShow,
    getLatestEvents,
    goToMarketPlace,
    coinUrl,
  } = useGetTopEvents();
  const { pathname } = useLocation();
  useClearSearchOnHome({ pathname });

  return (
    <>
      <div className="eventView">
        <div className="title">
          <h3>{msgs.topEvents}</h3>
          <span className="hrLine"></span>
        </div>
        {metaData?.total > topEventsShow ? (
          <ButtonCustom
            label={msgs.viewAll}
            btnBorder={true}
            onClick={goToMarketPlace}
          />
        ) : null}
      </div>
      <div className="eventSec" id="topEvents">
        <EventsCard
          coinUrl={coinUrl}
          data={getLatestEvents()}
          loading={metaData?.loading}
          filter={filter}
        />
      </div>
    </>
  );
};

export default TopEvents;
