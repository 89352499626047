/**
 * RecentActivity component displays a carousel of recent activities.
 * @returns {JSX.Element} - Rendered component
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import "./RecentActivity.scss";
import NoData from "../../Common/NoData/NoData";
import { msgs } from "../../utils/appConstants";
import Path from "../../Routing/Constant/RoutePaths";
import RecentActivityCard from "./RecentActivityCard";
import { useGetActivity } from "../../Hooks/useGetActivity";
import { ButtonCustom } from "../../Common/ButtonCustom/ButtonCustom";
import { Activity } from "../../assets/StoreAsset/StoreAsset";

const RecentActivity = () => {
  const navigate = useNavigate();
  const { metaData, coinUrl } = useGetActivity(false);

  // Handle navigation to the activity page
  const handleViewAllClick = () => {
    navigate(Path?.ACTIVITY);
  };

  return (
    <div className="recentActivity">
      <div className="recentHeading">
        <h4>
          <span>
            <Activity />
          </span>
          {msgs.recentActivity}
        </h4>
        <div className="recentBtn">
          {/* Show 'View All' button if there are recent activities */}
          {metaData?.recentAvitvityData?.length ? (
            <ButtonCustom
              label={msgs.viewAll}
              btnBorder={true}
              onClick={handleViewAllClick}
            />
          ) : null}
        </div>
      </div>
      {/* Display carousel or NoData component based on availability of recent activities */}
      {metaData?.recentAvitvityData?.length ? (
        <>
          {metaData?.recentAvitvityData?.map((item, index) => (
            <RecentActivityCard data={item} key={index} coinUrl={coinUrl} />
          ))}
        </>
      ) : (
        // Show NoData component if no recent activities
        <NoData loading={metaData?.loading} data="Activities" />
      )}
    </div>
  );
};

export default RecentActivity;
